import React from "react"
import { graphql } from "gatsby"

import { CinemaTile, Layout } from "../components"

import styles from "./index.module.css"

export default ({ data }) => {
  const cinemas = data.allContentfulCinema.edges.map((edge) => edge.node)

  return (
    <Layout>
      <div className={styles.gridContainer}>
        {cinemas.map((cinema) => (
          <CinemaTile cinema={cinema} />
        ))}
      </div>
    </Layout>
  )
}

export const query = graphql`
  query {
    allContentfulCinema {
      edges {
        node {
          id
          url
          title
          city
          country
          yearOfReopening
          yearOfFirstOpening
          yearOfConstruction
          images {
            title
            image {
              fluid(maxHeight: 500, maxWidth: 500) {
                ...GatsbyContentfulFluid
              }
            }
          }
          updatedAt
        }
      }
    }
  }
`
